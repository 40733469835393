import React from 'react'
import Layout from '../components/layout/layout'
import PageHeader from '../components/pageheader'

const NotFoundPage = () => {
  return (
    <Layout>
      <PageHeader title="This page cannot be found 😥" />
    </Layout>
  )
}

export default NotFoundPage
